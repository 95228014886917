import cookie from 'react-cookies';
import {
  SET_ROUTE, GET_PERSON_DETAIL, GET_PROBLEM_DETAIL, GET_CONFIG,
  SET_DATA, ADD_LOADING, REMOVE_LOADING, CLOSE, GET_THEMES,
  SUBMIT_PROBLEM, SET_SITE_URL, GET_PLACE, SEARCH_ADDRESSES,
  ERROR_SEARCH_ADDRESS, GET_ADDRESS, SET_TYPICAL_PROBLEM_ID,
  GET_TYPICAL_PROBLEM_DETAIL, ERROR_GET_TYPICAL_PROBLEM_DETAIL,
  CHANGE_AMOUNT_IMAGE
} from "../constants/actionTypes";


const addAuthToken = (val) => {
  const csrftoken = cookie.load('csrftoken'),
    token = cookie.load('sessionid2');

  if (token)
    val['Authorization'] = `Token ${token}`;
  if (csrftoken)
    val['X-CSRFToken'] = csrftoken;

  return val
};

export const setRoute = nextValue => dispatch => {
  dispatch({type: SET_ROUTE, value: nextValue});
};

export const getPersonDetail = personId => (dispatch, getState) => {
  const {siteUrl} = getState();

  fetch(`${siteUrl}/edw/api/entities/${personId}/`, {
    method: "GET",
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include'
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];
      if (statusCode === 200 || statusCode === 201) {
        dispatch({type: GET_PERSON_DETAIL, problemId: data.particular_problem_draft_id, model: data.entity_model});
        dispatch({type: ADD_LOADING, loading: "person"});
      } else
        dispatch({type: 'ERROR_GET_PERSON_DETAIL'});
    });
};

export const getProblemDetail = problemId => (dispatch, getState) => {
  const {siteUrl} = getState();

  fetch(`${siteUrl}/edw/api/entities/${problemId}/`, {
    method: "GET",
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include'
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];
      if (statusCode === 200 || statusCode === 201) {
        dispatch({type: GET_PROBLEM_DETAIL, problem: data});
        dispatch({type: ADD_LOADING, loading: "problem"});
      } else
        dispatch({type: 'ERROR_GET_PROBLEM_DETAIL'});
    });
};

export const getConfig = () => (dispatch, getState) => {
  const {siteUrl} = getState();

  fetch(`${siteUrl}/api/config/`)
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1],
        config = {
          LAT_DEFAULT: +data.LAT_DEFAULT,
          LNG_DEFAULT: +data.LNG_DEFAULT,
          ALL_PARTICULAR_PROBLEM_DATAMART_SLUG: data.ALL_PARTICULAR_PROBLEM_DATAMART_SLUG,
          YANDEX_MAPS_API_KEY: data.YANDEX_MAPS_API_KEY,
          LAT_LNG_SOUTHWEST: data.LAT_LNG_SOUTHWEST,
          LAT_LNG_NORTHEAST: data.LAT_LNG_NORTHEAST,
          REGION_SEARCH_HELPER_JSON: data.REGION_SEARCH_HELPER_JSON,
          PORTAL_NAME: data.PORTAL_NAME,
          REGION_NAME_GENITIVE: data.REGION_NAME_GENITIVE,
          MAX_SEARCH_LENGTH: data.MAX_SEARCH_LENGTH
        };
      if (statusCode === 200 || statusCode === 201) {
        dispatch({type: GET_CONFIG, config});
        dispatch({type: ADD_LOADING, loading: "config"});
      } else
        dispatch({type: 'ERROR_GET_CONFIG'});
    });
};

export const setData = dataObj => (dispatch, getState) => {
  const {config, problem, siteUrl} = getState(),
    dataMart = config.ALL_PARTICULAR_PROBLEM_DATAMART_SLUG;

  let isDescription = false,
    isGeoposition = false,
    isTypicalProblem = false,
    data = [{
      "id": problem.id
    }];

  Object.entries(dataObj).map(field => {
    // field[1] = value
    data[0][field[0]] = field[1];
    if (field[0] === "description")
      isDescription = true;
    else if (field[0] === "geoposition")
      isGeoposition = true;
    else if (field[0] === "typical_problem_id")
      isTypicalProblem = true;
  });

  fetch(`${siteUrl}/edw/api/data-marts/${dataMart}/entities/`, {
    method: 'POST',
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include',
    body: JSON.stringify(data)
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];
      if (statusCode === 200 || statusCode === 201) {
        delete data[0].description;
        dispatch({type: SET_DATA, data: data[0]});

        if (isDescription)
          dispatch({type: ADD_LOADING, loading: "setDescription"});

        if (isTypicalProblem)
          dispatch({type: ADD_LOADING, loading: "sendData"});

        if (isGeoposition)
          dispatch({type: ADD_LOADING, loading: "setGeoposition"})

      } else {
        let messageError = '';
        if (data.__all__)
          messageError = data.__all__;
        else if (data.non_field_errors)
          messageError = data.non_field_errors;
        else if (data.detail)
          messageError = data.detail;
        else if (data.token)
          messageError = data.token;
        else
          messageError = data[0];

        dispatch({type: "ERROR_DATA"})
      }
    })
};

export const close = () => (dispatch, getState) => {
  const {router} = getState(),
    container = document.querySelector(".smart-assist");

  parent.postMessage("closeAuth", "*");

  container.classList.add("d-none");
  dispatch({type: CLOSE});
  if (router === 4) {
    dispatch({type: SET_ROUTE, value: 1});
    dispatch({type: REMOVE_LOADING, clearAll: true});
    dispatch({type: SET_TYPICAL_PROBLEM_ID, typicalProblemId: null})
  }
};

export const getPlace = () => (dispatch, getState) => {
  const {siteUrl, problem, config} = getState(),
    {place} = problem;

  let geoposition = "";

  if (place && place.extra)
    geoposition = place && place.extra.geoposition;
  else
    geoposition = `${config.LAT_DEFAULT},${config.LNG_DEFAULT}`;

  fetch(`${siteUrl}/search/more_like_this/?m=nash_region.place&g=${geoposition}`, {
    method: "GET",
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include'
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];

      dispatch({type: GET_PLACE, place: data});
      dispatch({type: REMOVE_LOADING, loading: "setGeoposition"});
      dispatch({type: ADD_LOADING, loading: "place"});
    })
};

export const getThemes = description => (dispatch, getState) => {
  const {siteUrl, place, config} = getState(),
    {MAX_SEARCH_LENGTH} = config;

  let themes = [];

  let like = "",
    unlike = "";

  place.results && place.results.map(item => {
    if (item.score > 0)
      like += ` ${item.title}`;
    else
      unlike += ` ${item.title}`;
  });

  const symbols = "0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM";
  let randomKey = "";

  const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

  for (let i = 0; i < 16; i++)
    randomKey += symbols[getRandomInt(62)];

  const regex = /<.*?>|&nbsp;/gi;
  description = description ? description.replace(regex, ' ') : null;

  // Деление исключает превышение длины запроса при переводе в кодировку (1 русский символ = 6 в кодировке)
  const lenDescription = Math.floor((MAX_SEARCH_LENGTH - unlike.length * 6 - like.length * 6 - randomKey.length) / 6);

  description = description.slice(0, lenDescription);

  //todo: переделать на POST
  fetch(`${siteUrl}/search/more_like_this/?m=nash_region.particularproblem&u=${unlike}&iu=${like}&q=${description}&_=${randomKey}`, {
    method: "GET",
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include'
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];

      themes = data.results;
      dispatch({type: GET_THEMES, themes});
      dispatch({type: ADD_LOADING, loading: "getThemes"});
    })
    .catch(err => {
      console.error(err);
      dispatch({type: ADD_LOADING, loading: "getThemes"});
    });
};

export const submitProblem = () => (dispatch, getState) => {
  const {config, personDetail, siteUrl} = getState(),
    {problemId} = personDetail,
    csrftoken = cookie.load('csrftoken'),
    data = [{"id": problemId, "transition": "draft_to_detected", "source_of_message": 10}];

  fetch(`${siteUrl}/edw/api/data-marts/${config.ALL_PARTICULAR_PROBLEM_DATAMART_SLUG}/entities/`, {
    method: "POST",
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include',
    body: JSON.stringify(data)
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];
      if (statusCode === 200 || statusCode === 201) {
        dispatch({type: SUBMIT_PROBLEM});
        dispatch({type: ADD_LOADING, loading: "submitProblem"});
      } else {
        let messageError = '';
        if (data.__all__)
          messageError = data.__all__;
        else if (data.non_field_errors)
          messageError = data.non_field_errors;
        else if (data.detail)
          messageError = data.detail;
        else if (data.token)
          messageError = data.token;
        else
          messageError = data[0];

        dispatch({type: "ERROR_SUBMIT"})
      }
    })
};

export const setSiteUrl = (protocol, domain) => dispatch => {
  const url = `${protocol}://${domain}`;

  dispatch({type: SET_SITE_URL, siteUrl: url});
  dispatch({type: ADD_LOADING, loading: "siteUrl"});
};

export const removeLoading = loading => dispatch => {
  dispatch({type: REMOVE_LOADING, loading});
};

export const addLoading = loading => dispatch => {
  dispatch({type: ADD_LOADING, loading});
};

// Функция используется для поиска места по координатам
export const getSearchAddress = (ymaps, coordinates = []) => dispatch => {
  ymaps.geocode(coordinates, {
    results: 1
  })
    .then(res => {
      const result = res.geoObjects.toArray();

      const searchAddress = {
        value: result[0].properties._data.text,
        coordinates: result[0].geometry.getCoordinates(),
        label: result[0].properties._data.text
      };

      dispatch({type: GET_ADDRESS, searchAddress});
    })
    .catch(error => {
      dispatch({type: ERROR_SEARCH_ADDRESS, error});
    })
};

// Функция используется для поиска мест по тексту
export const geocodeAddress = (ymaps, searchAddress = "") => (dispatch, getState) => {
  const {config} = getState(),
    {REGION_SEARCH_HELPER_JSON} = config;

  const address = `${JSON.parse(REGION_SEARCH_HELPER_JSON).ru[1]}${searchAddress}`;

  if (searchAddress.length < 5)
    dispatch({type: SEARCH_ADDRESSES, searchAddresses: []});
  else {
    ymaps.geocode(address)
      .then(res => {
        const result = res.geoObjects.toArray();

        const searchAddresses = result.map(item => {
          return {
            value: item.properties._data.text,
            coordinates: item.geometry.getCoordinates(),
            label: item.properties._data.text
          }
        });

        dispatch({type: SEARCH_ADDRESSES, searchAddresses});
      })
      .catch(error => {
        dispatch({type: ERROR_SEARCH_ADDRESS, error});
      })
  }
};

export const setTypicalProblemId = typicalProblemId => dispatch => {
  dispatch({type: SET_TYPICAL_PROBLEM_ID, typicalProblemId})
};

export const getTypicalProblemDetail = () => (dispatch, getState) => {
  const {typicalProblemId, siteUrl} = getState();

  fetch(`${siteUrl}/edw/api/entities/${typicalProblemId}/`, {
    method: "GET",
    headers: addAuthToken({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': 'no-cache'
    }),
    credentials: 'include'
  })
    .then(response => {
      const statusCode = response.status,
        data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
        data = result[1];
      if (statusCode === 200 || statusCode === 201) {
        dispatch({
          type: GET_TYPICAL_PROBLEM_DETAIL,
          is_image_required: data.is_image_required,
          deadline: data.deadline,
          conditions: data.conditions || {}
        });
        dispatch({type: ADD_LOADING, loading: "typicalProblemDetail"});
      } else
        dispatch({type: ERROR_GET_TYPICAL_PROBLEM_DETAIL, data});
    });
};

export const changeAmountImage = image => dispatch => {
  dispatch({type: CHANGE_AMOUNT_IMAGE, image})
};
