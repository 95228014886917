import {
  setRoute,
  getPersonDetail,
  getProblemDetail,
  getConfig,
  setData,
  close,
  getPlace,
  getThemes,
  submitProblem,
  setSiteUrl,
  removeLoading,
  addLoading,
  getSearchAddress,
  geocodeAddress,
  setTypicalProblemId,
  getTypicalProblemDetail,
  changeAmountImage
} from './ProblemActions'

export default {
  setRoute,
  getPersonDetail,
  getProblemDetail,
  getConfig,
  setData,
  close,
  getPlace,
  getThemes,
  submitProblem,
  setSiteUrl,
  removeLoading,
  addLoading,
  getSearchAddress,
  geocodeAddress,
  setTypicalProblemId,
  getTypicalProblemDetail,
  changeAmountImage
}