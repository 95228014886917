import React from 'react';
import { Provider } from 'react-redux';
import Dropzone from "./components/Dropzone";
import { default as configureDropzoneStore } from './store/configureStore';


const App = props => {
  const store = configureDropzoneStore();

  return(
    <Provider store={store}>
      <Dropzone {...props}/>
    </Provider>
  )
};

export default App;