import { combineReducers } from 'redux';
import {
  images,
  loading
} from "./dropzone";


export default combineReducers({
  images,
  loading
});