require('es6-promise').polyfill();
require('isomorphic-fetch');

import React from 'react';
import { render } from 'react-dom';

import { default as configureProblemCreateStore } from './problem-create/store/configureStore';
import ProblemForm from "./problem-create";


const problemCreateFormContainer = document.getElementById('problem-create-form-container');

if (problemCreateFormContainer) {
  const problemStore = configureProblemCreateStore(),
        problemAttrs = problemCreateFormContainer.attributes;
  render(<ProblemForm store={problemStore} problemAttrs={problemAttrs}/>, problemCreateFormContainer);
}

// enable hot module relaoding for the dev server
if (process.env.NODE_ENV === 'development')
  module.hot.accept();
