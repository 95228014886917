import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../actions';
import {
  Button, Panel, Form, Alert
} from 'react-bootstrap';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { LoopCircleLoading } from 'react-loadingg';


let coordinates = [];

const mapModules = [
  'control.ZoomControl',
  'geolocation',
  'geocode'
];

const StepOne = props => {
  const { personId, problem, personDetail, loading, config, address } = props,
        { place } = problem;

  const mapState = {
    center: coordinates,
    zoom: 16,
    controls: [
      'zoomControl',
    ],
    behaviors: [
      'drag',
      'dblClickZoom',
      'rightMouseButtonMagnifier',
      'multiTouch',
      'scrollZoom'
    ]
  };

  const [map, setMap] = useState(null),
        [instanceMap, setInstanceMap] = useState(null),
        [next, setNext] = useState(null),
        [hasPlace, setHasPlace] = useState(false),
        [openSearchAddresses, setOpenSearchAddresses] = useState( false),
        [inputValue, setInputValue] = useState(""),
        [allowNextOnEnter, setAllowNextOnEnter] = useState(false);

  useEffect(() => {
    if (loading.some(item => item === "siteUrl") && !loading.some(item => item === "person"))
      props.getPersonDetail(personId);

    if (personDetail.model === "privateperson") {
      if (loading.some(item => item === "person") && !loading.some(item => item === "config"))
        props.getConfig();
      else if (loading.some(item => item === "config") && !loading.some(item => item === "problem"))
        props.getProblemDetail(personDetail.problemId);
      else if (next && loading.some(item => item === "setGeoposition")) {
        props.setRoute(2);
        props.getPlace();
        props.removeLoading("setLoadingGeoposition");
      }
    }
  }, [loading]);

  useEffect(() => {
    coordinates = [config.LAT_DEFAULT, config.LNG_DEFAULT];
  }, [config]);

  useEffect(() => {
    if (place && place.extra) {
      coordinates = place.extra.geoposition.split(',');
      coordinates[0] = +coordinates[0];
      coordinates[1] = +coordinates[1];
      setHasPlace(true);
    }
  }, [place]);

  useEffect(() => {
    if(address && address.received && hasPlace)
      setInputValue(address.received.value);
  }, [address.received]);

  useEffect(() => {
    if (instanceMap && inputValue)
      instanceMap.setZoom(16, {duration: 1000});
  },[inputValue]);

  useEffect(() => {
    if (address.received && address.received.value === inputValue) {
      setAllowNextOnEnter(true);
    }
  });

  const nextStep = () => {
    const newGeoposition = `${coordinates[0]},${coordinates[1]}`;
    if (place && newGeoposition === place.extra.geoposition)
      props.addLoading("setGeoposition");
    else {
      props.setData({
        "geoposition": newGeoposition,
        "address": inputValue
      });
      props.addLoading("setLoadingGeoposition");
      props.removeLoading("problem");
      props.removeLoading("place");
    }
    setNext(true);
  };

  const setSearchCoordinates = item => {
    coordinates = item.coordinates;
    setInputValue(item.value);
    props.geocodeAddress(map, inputValue);
    setOpenSearchAddresses(false);
    props.getSearchAddress(map, coordinates);
    setHasPlace(true);
  };

  const loadMap = ymaps => {
    setMap(ymaps);
    props.getSearchAddress(ymaps, coordinates);
  };

  const dragPin = e => {
    coordinates = e.get('target').geometry.getCoordinates();
    props.getSearchAddress(map, coordinates);
    setHasPlace(true);
  };

  if (loading.some(item => item === "person") && personDetail.model !== "privateperson"){
    return(
      <Panel bsStyle="primary">

        <Panel.Heading>
          <Panel.Title className="d-flex justify-content-between"componentClass="h6">
            <span>Сообщить о проблеме</span>
            <i className="fa fa-times" aria-hidden="true" onClick={() => props.close()}/>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body className="d-flex justify-content-center align-items-center">
          <Alert bsStyle="danger" >
            <h4 className="text-center">Только частное лицо может оставлять сообщение</h4>
          </Alert>
        </Panel.Body>

      </Panel>
    )
  }

  return (
    <Panel bsStyle="primary">

      <Panel.Heading>
        <Panel.Title className="d-flex justify-content-between"componentClass="h6">
          <span>Сообщить о проблеме</span>
          <i className="fa fa-times close-widget" aria-hidden="true" onClick={() => props.close()}/>
        </Panel.Title>
      </Panel.Heading>

      {!loading.some(item => item === "setLoadingGeoposition") && loading.some(item => item === "problem") ?
        <Panel.Body className="step-one">
          <Panel.Title componentClass="h6" className="mb-4">
            <strong>ШАГ 1 из 3.</strong> Укажите адрес проблемы
          </Panel.Title>
          <Form>
            <div className="search-address">
              <input name="address" type="text" placeholder="Начните ввод адреса ..." autoComplete="off"
                     onChange={e => {
                       if (allowNextOnEnter) setAllowNextOnEnter(false);
                       props.geocodeAddress(map, e.target.value);
                       setInputValue(e.target.value);
                       if (inputValue)
                         setOpenSearchAddresses(true);
                       else
                         setOpenSearchAddresses(false);
                     }}
                     onKeyDown={e => {
                       if (e.key === 'Enter') {
                         e.preventDefault();

                         if (allowNextOnEnter) {
                           nextStep();
                         } else {
                           if (openSearchAddresses) {
                             setSearchCoordinates(address.searchAddresses[0]);
                           }
                         }
                       }
                     }}
                     value={inputValue}/>
              {openSearchAddresses &&
                <div className="search-address-container">
                  {address.searchAddresses && address.searchAddresses.map((item, key) => {
                    return (
                    <div className="search-address-container-item" key={key} onClick={() => setSearchCoordinates(item)}>
                      <p>{item.value}</p>
                    </div>)
                  })}
                </div>
              }
            </div>
          </Form>
          <YMaps
            query={{
              ns: "use-load-option",
              apikey: config.YANDEX_MAPS_API_KEY
            }}>
            <Map
              onLoad={ymaps => loadMap(ymaps)}
              modules={mapModules}
              width="100%"
              className="map"
              state={mapState}
              options={{suppressMapOpenBlock: true}}
              instanceRef={ymaps => setInstanceMap(ymaps)}>
              <Placemark
                onDragEnd={e => dragPin(e)}
                geometry={coordinates}
                options={{draggable: true}}/>
            </Map>
          </YMaps>
          <div className="containerBtn">
            <Button bsStyle="info" className="next" disabled={!hasPlace} bsSize="sm" onClick={() => nextStep()}>
              Продолжить&nbsp;&#8594;
            </Button>
          </div>
        </Panel.Body>
        :
        <Panel.Body>
          <LoopCircleLoading color="#337AB7"/>
        </Panel.Body>
      }

    </Panel>
  )
};

const mapStateToProps = state => ({
  personDetail: state.personDetail,
  problem: state.problem,
  config: state.config,
  loading: state.loading,
  address: state.address
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
