import { combineReducers } from 'redux'
import {
  router, personDetail, problem, config, loading,
  close, themes, siteUrl, place, address, typicalProblemId,
  typicalProblem, amountImage
} from "./problem";


export default combineReducers({
  router,
  personDetail,
  problem,
  config,
  loading,
  close,
  themes,
  siteUrl,
  place,
  address,
  typicalProblemId,
  typicalProblem,
  amountImage
})