// actions
export const SET_ROUTE = 'SET_ROUTE';
export const GET_PERSON_DETAIL = 'GET_PERSON_DETAIL';
export const GET_PROBLEM_DETAIL = 'GET_PROBLEM_DETAIL';
export const GET_CONFIG = 'GET_CONFIG';
export const SET_DATA = 'SET_DATA';
export const ADD_LOADING = 'ADD_LOADING';
export const REMOVE_LOADING = 'REMOVE_LOADING';
export const CLOSE = 'CLOSE';
export const GET_PLACE = 'GET_PLACE';
export const GET_THEMES = 'GET_THEMES';
export const SUBMIT_PROBLEM = 'SUBMIT_PROBLEM';
export const SET_SITE_URL = 'SET_SITE_URL';
export const GET_ADDRESS = 'GET_ADDRESS';
export const SEARCH_ADDRESSES = 'SEARCH_ADDRESSES';
export const ERROR_SEARCH_ADDRESS = 'ERROR_SEARCH_ADDRESS';
export const SET_TYPICAL_PROBLEM_ID = 'SET_TYPICAL_PROBLEM_ID';
export const GET_TYPICAL_PROBLEM_DETAIL = 'GET_TYPICAL_PROBLEM_DETAIL';
export const ERROR_GET_TYPICAL_PROBLEM_DETAIL = 'ERROR_GET_TYPICAL_PROBLEM_DETAIL';
export const CHANGE_AMOUNT_IMAGE = 'CHANGE_AMOUNT_IMAGE';

// routes
export const ROUTES = {
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
  FINISH_SCREEN: 4
};
