import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../actions';
import { ROUTES } from "../constants/actionTypes";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import FinishScreen from "./FinishScreen";

const Router = props => {
  const {route, problemAttrs, setSiteUrl} = props,
        personId = problemAttrs.getNamedItem('data-person-id').value;

  useEffect(() => {
    const protocol = problemAttrs.getNamedItem('data-protocol').value,
          domain = problemAttrs.getNamedItem('data-domain').value;

    setSiteUrl(protocol, domain);
  }, []);

  switch (route) {
    case ROUTES.STEP_ONE:
      return <StepOne personId={personId}/>;
    case ROUTES.STEP_TWO:
      return <StepTwo />;
    case ROUTES.STEP_THREE:
      return <StepThree />;
    case ROUTES.FINISH_SCREEN:
      return <FinishScreen/>
  }
};


const mapStateToProps = state => ({
  route: state.router
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Router);