import {
  ROUTES, SET_ROUTE, GET_PERSON_DETAIL, GET_PROBLEM_DETAIL,
  GET_CONFIG, SET_DATA, ADD_LOADING, REMOVE_LOADING, CLOSE,
  GET_THEMES, SUBMIT_PROBLEM, SET_SITE_URL, GET_PLACE,
  SEARCH_ADDRESSES, ERROR_SEARCH_ADDRESS, GET_ADDRESS,
  SET_TYPICAL_PROBLEM_ID, GET_TYPICAL_PROBLEM_DETAIL,
  ERROR_GET_TYPICAL_PROBLEM_DETAIL, CHANGE_AMOUNT_IMAGE
} from "../constants/actionTypes";

export const router = (state = ROUTES.STEP_ONE, action) => {
  switch (action.type) {
    case SET_ROUTE:
      return action.value;

    default:
      return state;
  }
};

export const personDetail = (state = {}, action) => {
  switch (action.type) {
    case GET_PERSON_DETAIL:
      return {
        problemId: action.problemId,
        model: action.model
      };

    default:
      return state
  }
};

export const problem = (state = {}, action) => {
  switch (action.type) {
    case GET_PROBLEM_DETAIL:
      return action.problem ;

    case SET_DATA:
      return {...state, ...action.data};

    case SUBMIT_PROBLEM:
      return state;

    default:
      return state
  }
};

export const config = (state = {}, action) => {
  switch (action.type) {
    case GET_CONFIG:
      return action.config;

    default:
      return state;
  }
};

export const loading = (state = [], action) => {
  switch (action.type) {
    case ADD_LOADING:
      if(!state.some(item => action.loading && item === action.loading))
        return [...state, action.loading];
      else
        return state;

    case REMOVE_LOADING:
      if (!action.clearAll)
        return state.filter(loading => loading !== action.loading);
      else
        return ["siteUrl"];

    default:
      return state;
  }
};

export const close = (state = {}, action) => {
  switch (action.type) {
    case CLOSE:
      return state;

    default:
      return state;
  }
};

export const themes = (state = {}, action) => {
  switch (action.type) {
    case GET_THEMES:
      return action.themes;

    default:
      return state;
  }
};

export const siteUrl = (state = {}, action) => {
  switch (action.type) {
    case SET_SITE_URL:
      return action.siteUrl;

    default:
      return state;
  }
};

export const place = (state = {}, action) => {
  switch (action.type) {
    case GET_PLACE:
      return action.place;

    default:
      return state;
  }
};

export const address = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_ADDRESSES:
      return {...state, searchAddresses: action.searchAddresses};

    case GET_ADDRESS:
      return {...state, received: action.searchAddress};

    case ERROR_SEARCH_ADDRESS:
      console.error(action.error);
      return state;

    default:
      return state;
  }
};

export const typicalProblemId = (state = null, action) => {
  switch (action.type) {
    case SET_TYPICAL_PROBLEM_ID:
      return action.typicalProblemId;

    default:
      return state;
  }
};

export const typicalProblem = (state = {}, action) => {
  switch (action.type) {
    case GET_TYPICAL_PROBLEM_DETAIL:
      return {
        is_image_required: action.is_image_required,
        deadline: action.deadline,
        conditions: action.conditions,
      };

    case ERROR_GET_TYPICAL_PROBLEM_DETAIL:
      console.error(action.data);
      return state;

    default:
      return state;
  }
};

export const amountImage = (state = 0, action) => {
  switch (action.type) {
    case CHANGE_AMOUNT_IMAGE:
      return action.image;

    default:
      return state;
  }
};
