import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Panel, Alert, Checkbox} from 'react-bootstrap';
import ActionCreators from "../actions";
import Dropzone from "../../../components/Dropzone/index";
import {LoopCircleLoading} from 'react-loadingg';


let amountImages = 0;

const StepThree = props => {

  const {siteUrl, personDetail, loading, typicalProblem, amountImage, problem} = props,
    {private_person, place, description, is_publicly_available} = problem;

  const [isSubmit, setIsSubmit] = useState(false);
  const [oauth, setOauth] = useState(null);

  const submit = () => {
    props.submitProblem();
    setIsSubmit(true);
  };

  useEffect(() => {
    props.getProblemDetail(personDetail.problemId);
  }, []);

  useEffect(() => {
    if (loading.some(item => item === "problem") && !loading.some(item => item === "typicalProblemDetail"))
      props.getTypicalProblemDetail();
    else if (loading.some(item => item === "submitProblem"))
      props.setRoute(4);
  }, [loading]);

  useEffect(() => {
    if (Object.keys(typicalProblem).length) {
      const {conditions} = typicalProblem;
      setOauth(conditions.oauth || null);
    }
  },[typicalProblem]);

  const prev = () => {
    props.setRoute(2);
    props.removeLoading("typicalProblemDetail");
  };

  const changeImage = images => {
    props.changeAmountImage(images);
  };

  const checkCorrectnessData = () => {
    if (typicalProblem && typicalProblem.is_image_required)
      return !!amountImage;
    else
      return true
  };

  const socialPanel = oauth ? (
    <Alert bsStyle="danger" className="text-center">
      Сообщить о проблеме по данной тематике могут пользователи, зарегистированные через
      {oauth.map((array, key) => (
        <span key={key}> <a href={array.url}>{array.name}</a>
          {oauth.length === key + 2 && " или"}
          {oauth.length > key + 2 && ","}
        </span>
      ))}.
    </Alert>) : null;

  return (
    <Panel bsStyle="primary">

      <Panel.Heading>
        <Panel.Title className="d-flex justify-content-between" componentClass="h6">
          <span>Сообщить о проблеме</span>
          <i className="fa fa-times close-widget" aria-hidden="true" onClick={() => props.close()}/>
        </Panel.Title>
      </Panel.Heading>

      {loading.some(item => item === "typicalProblemDetail") && !isSubmit ?
        <Panel.Body className="step-three">
          <Panel.Title componentClass="h6" className="mb-4">
            <strong>ШАГ 3 из 3.</strong>
            {!socialPanel ?
              <>
                {private_person?.entity_name}, Подтвердите информацию
                <br/>
              </>
              : null
            }
            <p><strong>{problem?.typical_problem?.entity_name}</strong></p>
          </Panel.Title>

          {!socialPanel ?
            <>
              <p className="place">
                <i className="fa fa-map-marker pin pin-black" aria-hidden="true"/>
                {place?.entity_name}
              </p>
              <div className="description" dangerouslySetInnerHTML={{__html: description}}/>
              {typicalProblem?.is_image_required ?
                <h5 className="text-danger">* необходимо подтвердить проблему фотоматериалами</h5>
                : null
              }
              <Dropzone countImage={changeImage} siteUrl={siteUrl} entityId={personDetail.problemId}/>
              <Checkbox defaultChecked={is_publicly_available}
                        onChange={() => props.setData({"is_publicly_available": !is_publicly_available})}>
                Опубликовать сообщение в открытом доступе
              </Checkbox>
            </>
            : socialPanel
          }
          <div className="containerBtn">
            <Button bsSize="sm" className="prev" onClick={prev}>
              &#8592;&nbsp;Вернуться
            </Button>
            {!socialPanel ?
              <Button bsStyle="info" className="next" disabled={!checkCorrectnessData()} bsSize="sm" onClick={submit}>
                Отправить
              </Button>
              : null
            }
          </div>
        </Panel.Body>
        :
        <Panel.Body>
          <LoopCircleLoading color="#337AB7"/>
        </Panel.Body>
      }

    </Panel>
  )
};

const mapStateToProps = state => ({
  personDetail: state.personDetail,
  problem: state.problem,
  loading: state.loading,
  siteUrl: state.siteUrl,
  typicalProblem: state.typicalProblem,
  amountImage: state.amountImage
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepThree);
