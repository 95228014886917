import cookie from 'react-cookies';
import { GET_IMAGES, UPLOAD_IMAGES, REMOVE_IMAGE, ADD_LOADING, REMOVE_LOADING, ERROR_DATA } from "../constants/actionTypes";

export const removeLoading = loading => dispatch => {
  dispatch({ type: REMOVE_LOADING, loading });
};

export const addLoading = loading => dispatch => {
  dispatch({ type: ADD_LOADING, loading });
};

const addAuthToken = (val) => {
  const csrftoken = cookie.load('csrftoken'),
        token = cookie.load('sessionid2');

  if (token)
    val['Authorization'] = `Token ${token}`;
  if (csrftoken)
    val['X-CSRFToken'] = csrftoken;

  return val
};

export const getImages = (siteUrl, entityId) => dispatch => {
  const urls = [
    `${siteUrl}/edw/api/entities/${entityId}/images`,
    `${siteUrl}/edw/api/entities/${entityId}/files`
  ]

  const requests = urls.map(url => fetch(url, {
    method: "GET",
    headers: addAuthToken({}),
    credentials: 'include'
  }));

  Promise.all(requests)
    .then(responses => Promise.all(responses.map(r => {
      if (r.status >= 400) {
        dispatch({ type: 'ERROR_IMAGES'});
      }
      return r.json();
    })))
    .then(responses => responses.map(response => response.results))
    .then(data => {
      if (data.length)
      dispatch({type: GET_IMAGES, images: data.flat()});
      dispatch({type: ADD_LOADING, loading: "getImages"});
    });
};

export const uploadImages = (siteUrl, image, entityId) => dispatch => {
  const formData = new FormData();
  const isPdf = image.name.endsWith('pdf');
  const saveUrlPath = isPdf ? 'entities-files': 'entities-images';

  formData.append('entity', entityId);
  formData.append(isPdf ? 'file': 'image', image);

  fetch(`${siteUrl}/edw/api/${saveUrlPath}/`,{
    method: 'POST',
    headers: addAuthToken({}),
    credentials: 'include',
    body: formData
    })
    .then(response => {
      const statusCode = response.status,
            data = response.json();
      return Promise.all([statusCode, data]);
    })
    .then(result => {
      const statusCode = result[0],
            data = result[1];
      if (statusCode === 200 || statusCode === 201) {
        dispatch({ type: UPLOAD_IMAGES });
        dispatch({ type: ADD_LOADING, loading: "uploadImages" });
      } else {
        let messageError = '';
        if (data.__all__)
          messageError = data.__all__;
        else if (data.non_field_errors)
          messageError = data.non_field_errors;
        else if (data.detail)
          messageError = data.detail;
        else if (data.token)
          messageError = data.token;
        else
          messageError = data[0];

        dispatch({ type: ERROR_DATA })
      }
    })
};

export const removeImage = (siteUrl, imageId, isPdf=false) => dispatch => {
  fetch(`${siteUrl}/edw/api/${isPdf? 'entities-files': 'entities-images'}/${imageId}/`, {
      method: 'DELETE',
      headers: addAuthToken({}),
      credentials: 'include'
    })
    .then(() => {
      dispatch({ type: REMOVE_IMAGE });
      dispatch({ type: ADD_LOADING, loading: "removeImages" });
    });
};
