import { GET_IMAGES, UPLOAD_IMAGES, REMOVE_IMAGE, ADD_LOADING, REMOVE_LOADING } from "../constants/actionTypes";


export const images = (state = [], action) => {
  switch (action.type) {
    case GET_IMAGES:
      return action.images;

    case UPLOAD_IMAGES:
    case REMOVE_IMAGE:
    default:
      return state;
  }
};

export const loading = (state = [], action) => {
  switch (action.type) {
    case ADD_LOADING:
      if(!state.some(item => action.loading && item === action.loading))
        return [...state, action.loading];
      else
        return [...state];

    case REMOVE_LOADING:
      return state.filter(loading => loading !== action.loading);

    default:
      return state;
  }
};